<template>
  <nav class="flex items-center space-x-2 text-sm">
    <NuxtLink
      v-for="(item, index) in breadcrumbItems"
      :key="item.name"
      :to="item.path"

      class="hover:text-primary transition-colors"
      :class="{
        'text-neutral': index !== breadcrumbItems.length - 1,
        'font-bold pointer-events-none': index === breadcrumbItems.length - 1
      }"
    >
      {{ item.title }}
      <span v-if="index !== breadcrumbItems.length - 1" class="ml-2 text-neutral">/</span>
    </NuxtLink>
  </nav>
</template>

<script setup lang="ts">
const { breadcrumbItems } = useAdmin()

defineOptions({
  name: 'BuilderBreadcrumb'
})
</script>
