<template>
  <div
    id="layout"
    class="isolate grid min-h-dvh grid-rows-[auto,minmax(0,1fr),auto]"
  >
    <PageHeader class="shadow-dark/25 sticky top-0 shadow print:static" />

    <main id="main" class="relative">
      <MessageController />
      <div class="relative flex min-h-full flex-col">
        <PageContainer>
          <PageGrid>
            <PageGridColumn class="!space-y-2">
              <ClientOnly>
                <BuilderBreadcrumb />
                <PageSectionHeading
                  :title
                  :tag="PAGE_HEADING_TAG.H3"
                  class="mt-0"
                />
              </ClientOnly>
            </PageGridColumn>
          </PageGrid>
        </PageContainer>

        <div class="relative flex flex-grow flex-col overflow-hidden">
          <NuxtPage
            :transition="{
              name: slideDirection,
              mode: 'out-in',
              enterActiveClass:
                'absolute inset-0 transform transition-all duration-200 ease-out',
              leaveActiveClass:
                'absolute inset-0 transform transition-all duration-200 ease-out',
              enterFromClass:
                slideDirection === 'slide-left'
                  ? '-translate-x-full opacity-0'
                  : 'translate-x-full opacity-0',
              leaveToClass:
                slideDirection === 'slide-left'
                  ? 'translate-x-full opacity-0'
                  : '-translate-x-full opacity-0',
            }"
          />
        </div>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import useAdmin from '@builder/composables/use-admin'
import useCms from '@cms/composables/use-cms'
import useDesign from '@theme/composables/use-design'
import { PAGE_HEADING_TAG } from '@base/constants/page'

const { design: currentDesign } = useCms()
const { design, applyDesign } = useDesign()
const { title } = useAdmin()

const router = useRouter()
const slideDirection = ref('slide-left')

router.beforeEach((to, from) => {
  const toDepth = to.path.split('/').length
  const fromDepth = from.path.split('/').length
  slideDirection.value = toDepth > fromDepth ? 'slide-right' : 'slide-left'
})

watch(
  currentDesign,
  () => {
    design.value = currentDesign.value
    applyDesign()
  },
  { immediate: true }
)

defineOptions({
  name: 'AdminLayout',
})
</script>
