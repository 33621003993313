<template>
  <Dropdown
    :open="isOpen"
    class="hidden pl-0 pr-0 md:inline-flex"
    @open="isOpen = true"
    @close="isOpen = false"
  >
    <template #trigger>
      <Button
        :theme="BUTTON_THEME.TRANSPARENT"
        :icon="SVG_ICON.COG_OUTLINE"
        class="button-header button-sm !size-10 !min-w-0"
        @click="isOpen = !isOpen"
      />
    </template>

    <template #default>
      <div class="flex flex-col gap-2 px-4 md:max-h-96">
        <Divider :label="t('divider.config', { id: config?.id })" />
        <NuxtLink
          :to="`${ADMIN_URL}/cmsConfig/${config?.id}`"
          :external="true"
          :target="LINK_TARGET.BLANK"
          class="hover:text-primary w-full text-left text-sm"
        >
          {{ t('btn.editConfig') }}
        </NuxtLink>
        <button
          :type="BUTTON_TYPE.BUTTON"
          class="hover:text-primary w-full text-left text-sm"
          @click="openThemeRoller"
        >
          {{ t('btn.defaultDesign') }}
        </button>
        <NuxtLink
          :to="localeRoute({ name: ADMIN_ROUTE_NAMES.RAFFLE_LIST })"
          class="hover:text-primary w-full text-left text-sm"
          @click="isOpen = false"
        >
          {{ t('btn.manageRaffles') }}
        </NuxtLink>

        <Divider
          :label="t('divider.page', { id: page?.id ?? '-' })"
          class="mt-2"
        />
        <div
          class="flex flex-col gap-2"
          :class="{ 'pointer-events-none opacity-50': !page?.id }"
        >
          <NuxtLink
            :to="`${ADMIN_URL}/CmsPage/update/${page?.id}`"
            :external="true"
            :target="LINK_TARGET.BLANK"
            class="hover:text-primary w-full text-left text-sm"
          >
            {{ t('btn.editPage') }}
          </NuxtLink>

          <NuxtLink
            :to="`${ADMIN_URL}/cmsPage/updateComponents/${page?.id}`"
            :external="true"
            :target="LINK_TARGET.BLANK"
            class="hover:text-primary w-full text-left text-sm"
          >
            {{ t('btn.editComponents') }}
          </NuxtLink>
          <button
            :type="BUTTON_TYPE.BUTTON"
            class="hover:text-primary w-full text-left text-sm"
            @click="openThemeRoller"
          >
            {{ t('btn.pageDesign') }}
          </button>
        </div>
        <Divider
          :label="t('divider.logout')"
          class="mt-2"
        />
        <button
          :type="BUTTON_TYPE.BUTTON"
          class="hover:text-primary w-full text-left text-sm"
          @click="logout"
        >
          {{ t('btn.logout') }}
          </button>
      </div>
    </template>
  </Dropdown>

  <LazyThemeRoller
    v-if="isThemeRollerOpen"
    :open="isThemeRollerOpen"
    @close="isThemeRollerOpen = false"
  />
</template>

<script setup lang="ts">
import { ADMIN_ROUTE_NAMES } from '@builder/constants/routes'
import { SVG_ICON } from '@assets/constants/inline-svg'
import { BUTTON_THEME, BUTTON_TYPE } from '@base/constants/button'
import { LINK_TARGET } from '@base/constants/link'
import useCms from '@cms/composables/use-cms'
import useBackendToken from '@base/composables/use-backend-token'

const { t } = useI18n()
const route = useRoute()
const { config, page } = useCms()
const isOpen = ref(false)
const isThemeRollerOpen = ref(false)
const localeRoute = useLocaleRoute()

const ADMIN_URL = useRuntimeConfig().public.KHADMIN_URL

function openThemeRoller() {
  isThemeRollerOpen.value = true
  isOpen.value = false
}

async function logout() {
  const backendTokenCookie = useCookie(BACKEND_TOKEN_COOKIE_NAME)
  const { backendToken } = useBackendToken()
  backendTokenCookie.value = null
  backendToken.value = null

  await refreshNuxtData()
  navigateTo(route.fullPath)
}

defineOptions({
  name: 'Builder',
})
</script>

<i18n>
de:
  divider:
    page: "Cms Seite (ID: {id})"
    config: "Cms Konfiguration (ID: {id})"
    raffles: "Verlosungen"
    logout: "Logout"
  btn:
    defaultDesign: "Design bearbeiten"
    editConfig: "Konfiguration"
    editPage: "Einstellungen"
    editComponents: "Inhalte bearbeiten"
    pageDesign: "Design bearbeiten"
    manageRaffles: "Verlosungen verwalten"
    logout: "Logout"
es:
  divider:
    page: "Página (ID: {id})"
    config: "Configuración (ID: {id})"
    raffles: "Sorteos"
    logout: "Logout"
  btn:
    defaultDesign: "Editar diseño"
    editConfig: "Configuración"
    editPage: "Ajustes"
    editComponents: "Editar contenido"
    pageDesign: "Editar diseño"
    manageRaffles: "Verlosungen verwalten"
    logout: "Logout"
</i18n>
